import React, { Suspense, Component } from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import ReactModal from 'react-modal';

import { Container, Row, Col } from 'react-grid-system';

import Cookies from 'js-cookie';

import Loader from 'Components/Overlays/Loader/Loader/Loader';

import Page404 from 'Modules/Site/components/page404';
import Header from 'Modules/Site/components/header';
// import Footer from 'Modules/Site/components/footer';
import MainMenu from 'Modules/Site/components/main-menu';

import ZendeskFullScreen from 'Modules/Zendesk/ZendeskFullScreen';
import { getInitLang } from './i18n';
import HandleNoLangRoute from './Components/HandleNoLangRoute';
import ProtectedRoute from './Components/ProtectedRoute';

import { getUaID, getUaTip, getClasaCurenta } from './Redux/SessionHelper';
import { sessionInit } from './Redux/SessionActions';

import './Assets/Fonts/adservio/style.css';

import './app.less';
import { TYPE_ADMIN, TYPE_CADRU, TYPE_ELEV, TYPE_PARINTE } from './constants';

const RedirectHome = React.lazy(() => import('./Modules/Site/components/redirect-home'));
const Authpage = React.lazy(() => import('./Modules/Site/Authpage'));
const ReportCard = React.lazy(() => import('./Modules/ReportCard/ReportCard'));
const Messages = React.lazy(() => import('./Modules/Messages'));
const Foto = React.lazy(() => import('./Modules/Foto/Foto'));
const Anuar = React.lazy(() => import('./Modules/Anuar'));
const Library = React.lazy(() => import('./Modules/Library'));
const EduMarket = React.lazy(() => import('./Modules/EduMarket'));
const Homework = React.lazy(() => import('./Modules/Homework'));
const Preferinte = React.lazy(() => import('./Modules/Preferinte'));
const Files = React.lazy(() => import('./Modules/Files'));
const Groups = React.lazy(() => import('./Modules/Groups'));
const News = React.lazy(() => import('./Modules/News'));
const Progress = React.lazy(() => import('./Modules/Progress'));
const Admitere = React.lazy(() => import('./Modules/Admitere'));
const RolesAndPermissions = React.lazy(() => import('./Modules/RolesAndPermissions'));
const Calendar = React.lazy(() => import('./Modules/Calendar'));
const Documents = React.lazy(() => import('./Modules/Documents'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuVisible: false,
      hasCookiesAdsNative: !!Cookies.get('ads_native'),
    };

    ReactModal.setAppElement('body');
  }

  componentDidMount() {
    const { doSessionInit } = this.props;
    doSessionInit();
  }

  toggleMenu = (close) => {
    const { isMenuVisible } = this.state;

    this.setState({
      isMenuVisible: close ? false : !isMenuVisible,
    });
  };

  render() {
    const { uaID, sessionReady, schoolConfig, currentClass, mobileView } = this.props;
    const { ast: { astClasamentActiv = {} } = {} } = currentClass;
    const { isMenuVisible, hasCookiesAdsNative } = this.state;
    const lang = getInitLang();

    if (!sessionReady) return <Loader />;

    window.scrollTo(0, 0);
    if (window.location.pathname.match(/^\/([a-z]{2})\/?$/)) window.location = `/${lang}/auth`;

    return (
      <>
        <Router>
          <Container
            fluid
            style={{ padding: '0', height: '100vh' }}
            className={`${isMenuVisible ? 'withoutScroll' : ''}`}
          >
            <Suspense fallback={<Loader />}>
              {!(hasCookiesAdsNative || mobileView) && !window.location.pathname.includes('auth') && (
                <Route path="*" render={() => <Header onToggleMenu={this.toggleMenu} toggleMenu={isMenuVisible} />} />
              )}

              <Row className={`${uaID ? 'two-columns-content' : 'one-column-content'}`} nogutter>
                {uaID && !hasCookiesAdsNative && (
                  <Col lg={2} md={12} sm={12}>
                    <Route
                      path="*"
                      render={({
                        history: routeHistory,
                        location: routeLocation,
                        match: routeMatch,
                        staticContext: routeStaticContext,
                      }) => (
                        <MainMenu
                          history={routeHistory}
                          location={routeLocation}
                          match={routeMatch}
                          staticContext={routeStaticContext}
                          onToggleMenu={this.toggleMenu}
                          toggleMenu={isMenuVisible}
                        />
                      )}
                    />
                  </Col>
                )}

                <Route path="*" component={HandleNoLangRoute} />

                <Col lg={uaID && !hasCookiesAdsNative ? 10 : 12} md={12} sm={12}>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route exact path={`/${lang}`} component={() => <Redirect to={`/${lang}/auth`} />} />
                      <Route exact path={`/${lang}/auth`} component={() => (uaID ? <RedirectHome /> : <Authpage />)} />
                      <Route exact path={`/${lang}/loading`} component={() => <Loader />} />

                      <ProtectedRoute
                        path={`/${lang}/homework`}
                        component={Homework}
                        uaTip={[TYPE_CADRU]}
                        enabled={schoolConfig && +schoolConfig.teme.isActive === 1}
                      />

                      <ProtectedRoute
                        path={`/${lang}/library`}
                        component={Library}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
                        enabled={schoolConfig && +schoolConfig.biblioteca.isActive === 1}
                      />

                      <ProtectedRoute
                        path={`/${lang}/edumarket`}
                        component={EduMarket}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN]}
                        enabled={schoolConfig && +schoolConfig.edumarket.isActive === 1}
                      />

                      <ProtectedRoute path={`/${lang}/messages`} component={Messages} />

                      <ProtectedRoute
                        path={`/${lang}/report-card`}
                        component={ReportCard}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE]}
                      />

                      <ProtectedRoute
                        path={`/${lang}/foto`}
                        component={Foto}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
                      />

                      <ProtectedRoute
                        path={`/${lang}/anuar`}
                        component={Anuar}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
                      />

                      <ProtectedRoute path={`/${lang}/preferences`} component={Preferinte} />

                      <ProtectedRoute path={`/${lang}/files`} component={Files} />

                      <ProtectedRoute path={`/${lang}/news`} component={News} />

                      <Route path={`/${lang}/admission`} component={Admitere} />

                      <ProtectedRoute
                        path={`/${lang}/groups`}
                        component={Groups}
                        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
                      />

                      {!!+astClasamentActiv && (
                        <ProtectedRoute
                          path={`/${lang}/progress`}
                          component={Progress}
                          uaTip={[TYPE_ELEV, TYPE_PARINTE]}
                        />
                      )}

                      <ProtectedRoute
                        path={`/${lang}/configuration/roles-and-permissions/:id?`}
                        component={RolesAndPermissions}
                      />
                      <ProtectedRoute path={`/${lang}/calendar`} component={Calendar} />
                      <ProtectedRoute path={`/${lang}/main-documents`} uaTip={[TYPE_CADRU]} component={Documents} />

                      <Route exact path={`/${lang}/zendesk-support`} component={ZendeskFullScreen} />
                      <Route path="*" component={Page404} />

                      {/* <Route path={`/${lang}/schedule`} component={Schedule} /> */}
                    </Switch>
                  </Suspense>
                </Col>
              </Row>

              {/* {!(hasCookiesAdsNative || mobileView) && <Route path="*" component={Footer} />} */}
            </Suspense>
          </Container>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uaID: getUaID(state),
    uaTip: getUaTip(state),
    sessionReady: state.session.ready,
    schoolConfig: state.session.schoolConfig,
    currentClass: getClasaCurenta(state),
    mobileView: state.session.mobileView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSessionInit: () => {
      return dispatch(sessionInit());
    },
  };
};

App.propTypes = {
  doSessionInit: PropTypes.func.isRequired,
  uaID: PropTypes.string,
  sessionReady: PropTypes.bool,
  schoolConfig: PropTypes.instanceOf(Object),
  currentClass: PropTypes.instanceOf(Object),
  mobileView: PropTypes.bool,
};

App.defaultProps = {
  uaID: undefined,
  sessionReady: undefined,
  schoolConfig: undefined,
  currentClass: undefined,
  mobileView: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
