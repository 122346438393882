import { TYPE_ADMIN, TYPE_CADRU, TYPE_ELEV, TYPE_PARINTE, TYPE_PERS_INST } from '../../../constants';

export const TYPE_SECRETARIAT = 6;

const buildLegacyUrl = (language, userType, path) => {
  let currentType = '';

  switch (userType) {
    case TYPE_ELEV:
      currentType = 'student';
      break;
    case TYPE_PARINTE:
      currentType = 'parent';
      break;
    case TYPE_CADRU:
      currentType = 'teacher';
      break;
    case TYPE_ADMIN:
      currentType = 'admin';
      break;
    case TYPE_PERS_INST:
      currentType = 'institutions';
      break;
    case TYPE_SECRETARIAT:
      currentType = 'secretary';
      break;
    default:
      break;
  }

  return `/${language}${currentType ? `/${currentType}` : ''}${path ? `/${path}` : ''}`;
};

export default buildLegacyUrl;
