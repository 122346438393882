import { connect } from 'react-redux';
import { getUaTip, isDirector, isPersAds, isSecretar } from 'Redux/SessionHelper';
import MeniuDirectorSecretar from './MeniuDirectorSecretar';

const mapStateToProps = (state) => {
  return {
    uaTip: getUaTip(state),
    schoolConfig: state.session.schoolConfig,
    isDirector: isDirector(state),
    isSecretar: isSecretar(state),
    isPersAds: isPersAds(state),
  };
};
export default connect(mapStateToProps)(MeniuDirectorSecretar);
