import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import MeniuParinteElev from './components/meniu-parinte-elev';
import MeniuProfesor from './components/meniu-profesor';
import MeniuDirectorSecretar from './components/meniu-director-secretar';
import MeniuPersInst from './components/meniu-pers-inst';
import MeniuAdmin from './components/meniu-admin';
import MeniuPersAux from './components/meniu-pers-aux';
import { TYPE_ELEV, TYPE_PARINTE, TYPE_ADMIN, TYPE_PERS_INST } from '../../../../constants';
import './MainMenu.less';

const MainMenu = (props) => {
  const {
    match: { url },
    onToggleMenu,
    uaTip,
    isSecretar,
    isDirector,
    isCadruDidactic,
    isPersAux,
    isPersAds,
    toggleMenu,
  } = props;

  const wrapperRef = useRef(null);
  const meniuProf = !!(Cookies.get('MPR') == null || Cookies.get('MPR') === '1');

  // eslint-disable-next-line no-unused-vars
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onToggleMenu(true);
    }
  };

  // TODO: This is breaking the event bubbling, it should be implemented using a different aproach
  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  //   return () => document.removeEventListener('click', handleClickOutside);
  // }, []);

  useEffect(() => {
    onToggleMenu(true);
  }, [url]);

  return (
    <div className={`comp-mainmenu ${toggleMenu ? 'open-menu' : 'close-menu'}`}>
      <nav className={`mainmenu-nav nav-order-${uaTip}${!meniuProf ? '-director' : ''}`} ref={wrapperRef}>
        {(uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) && <MeniuParinteElev />}
        {isCadruDidactic && meniuProf && <MeniuProfesor />}
        {isPersAux && meniuProf && !isCadruDidactic && <MeniuPersAux />}
        {(isDirector || isSecretar || isPersAds) && !meniuProf && <MeniuDirectorSecretar />}
        {uaTip === TYPE_ADMIN && <MeniuAdmin />}
        {uaTip === TYPE_PERS_INST && <MeniuPersInst />}
      </nav>
    </div>
  );
};

MainMenu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  uaTip: PropTypes.string,
  isDirector: PropTypes.bool,
  isSecretar: PropTypes.bool,
  isCadruDidactic: PropTypes.bool,
  isPersAux: PropTypes.bool,
  isPersAds: PropTypes.bool,
  toggleMenu: PropTypes.bool,
  onToggleMenu: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

MainMenu.defaultProps = {
  match: {
    url: '',
  },
  uaTip: '',
  isDirector: false,
  isSecretar: false,
  isCadruDidactic: false,
  isPersAux: false,
  isPersAds: false,
  toggleMenu: false,
  onToggleMenu: () => {},
};

export default MainMenu;
