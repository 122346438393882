import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

export const getInitLang = (lng) => {
  if (lng) return lng;
  const { pathname } = window.location;

  // eslint-disable-next-line no-unused-vars
  const [empty, lang] = pathname.split('/');
  const match = lang.match(/^([a-z]{2})$/);

  return match ? match[0] : 'ro';
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{lng}/{ns}.json',
    },
    react: {
      useSuspense: true,
    },
    ns: ['common'],
    defaultNS: 'common',
    otherLanguages: ['en', 'ro', 'fr', 'hu', 'nl'],
    fallbackLng: 'ro',
    lng: getInitLang(),
    interpolation: {
      prefix: '{',
      suffix: '}',
    },
  });

export default i18n;
