import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Icon from 'Components/Content/Icon/Icon';
import { withRouter } from 'react-router';
import buildLegacyUrl from '../../../../helpers/build-legacy-url';
import addClassActive from '../../../../helpers/add-class-active';
import { TYPE_PERS_INST } from '../../../../../../constants';

class MeniuPersInst extends Component {
  constructor(props) {
    super(props);
    const {
      t,
      history,
      i18n: { language },
    } = this.props;

    const translations = {
      institutionsUrl: t('common:text_pers_inst_url'), // See helpers/constants.js
    };

    const verifyPathname = (paths) => {
      const urls = paths.map((path) => {
        return buildLegacyUrl(language, TYPE_PERS_INST, path, translations);
      });
      return urls.includes(`${history?.location?.pathname}`);
    };

    this.state = {
      statistici: verifyPathname([
        t('legacy-url.path.statistici_elevi'),
        t('legacy-url.path.statistici_note'),
        t('legacy-url.path.statistici_absente'),
        t('legacy-url.path.statistici_clase'),
      ]),
    };
  }

  expandMenu = (type) => {
    const { [type]: value } = this.state;
    this.setState({ [type]: !value });
  };

  render() {
    const { statistici } = this.state;
    const {
      t,
      i18n,
      history,
      i18n: { language },
    } = this.props;

    const pathname = history?.location?.pathname;
    const translations = {
      institutionsUrl: t('common:text_pers_inst_url'),
    };

    return (
      <>
        <a
          href={buildLegacyUrl(language, TYPE_PERS_INST, '', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_PERS_INST, '', translations))}
        >
          <span>{t('buttons_mm.scoli')}</span>
          <Icon icon="icon-school" />
        </a>

        <NavLink
          to={`/${i18n?.language}/messages`}
          isActive={(match, location) => {
            return location?.pathname.match(/messages(.*)$/);
          }}
        >
          <span>{t('buttons_mm.mesaje')}</span>
          <Icon icon="icon-mesagerie" />
        </NavLink>

        <NavLink
          to={`/${i18n?.language}/files`}
          isActive={(match, location) => {
            return location?.pathname.match(/files(.*)$/);
          }}
        >
          <span>{t('buttons_mm.fisiere')}</span>
          <Icon icon="icon-fisiere" />
        </NavLink>

        <button
          type="button"
          onClick={() => this.expandMenu('statistici')}
          className={`expand-menu ${statistici ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.statistici')}</span>
            {statistici ? (
              <Icon icon="icon-down-arrow" />
            ) : (
              <>
                <Icon icon="icon-down-arrow show-on-hover" />
                <Icon icon="icon-clasamente2 hide-on-hover" />
              </>
            )}
          </div>

          {statistici && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_students', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_students', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_elevi')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_grades', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_grades', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_note')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_attendance', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_attendance', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_absente')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_classes/final_average', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_PERS_INST, 'rankings_classes/final_average', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_clase')}</span>
              </a>
            </span>
          )}
        </button>

        <NavLink
          to={`/${i18n?.language}/news`}
          isActive={(match, location) => {
            return location?.pathname.match(/news(.*)$/);
          }}
        >
          <span>{t('buttons_mm.stiri')}</span>
          <Icon icon="icon-news" />
        </NavLink>
      </>
    );
  }
}

MeniuPersInst.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};

MeniuPersInst.defaultProps = {
  history: {
    location: {
      pathname: '',
    },
  },
};

export default withRouter(withTranslation(['Site', 'common'])(MeniuPersInst));
