import { connect } from 'react-redux';
import { getClasaCurenta, getUaID, getUaTip, isCadruDidactic, isDirector, isSecretar } from 'Redux/SessionHelper';
import { sessionSwitchAccount, sessionUnset } from 'Redux/SessionActions';
import Header from './Header';

const mapStateToProps = (state) => {
  return {
    userProfile: state.session.profile,
    cadru: state.session.cadru,
    elev: state.session.elev,
    uaID: getUaID(state),
    uaTip: getUaTip(state),
    clasaCurenta: getClasaCurenta(state),
    isDirector: isDirector(state),
    isSecretar: isSecretar(state),
    isCadruDidactic: isCadruDidactic(state),
    school: state.session.school,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionUnset: () => {
      return dispatch(sessionUnset());
    },
    sessionSwitchAccount: (account, profile) => {
      return dispatch(sessionSwitchAccount(account, profile));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
