import {
  FETCH_GROUP_DETAILS_SUCCESS,
  FETCH_GROUP_DETAILS_ERROR,
  LOAD_MESSAGES,
  LOAD_SUCCESS_MESSAGES,
} from './GroupsActions';

const initialState = {
  pending: true,
  groupDetails: [],
  error: null,
  pendingMessages: false,
};

const groupDetailsReducer = (state = initialState, action) => {
  const newChatMessages = [];
  switch (action.type) {
    case FETCH_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        pending: false,
        groupDetails: action.groupDetails,
      };
    case FETCH_GROUP_DETAILS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case LOAD_MESSAGES:
      newChatMessages[`${state.groupDetails.data[0].grpID}`] = action.chatMessages;
      return {
        ...state,
        chatMessages: newChatMessages,
        pendingMessages: true,
      };
    case LOAD_SUCCESS_MESSAGES:
      return {
        ...state,
        pendingMessages: false,
      };
    default:
      return state;
  }
};

export default groupDetailsReducer;
