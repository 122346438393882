import { connect } from 'react-redux';
import { getClasaCurenta, getUaID, getUaTip, isCadruDidactic, isDirector, isSecretar } from 'Redux/SessionHelper';
import MeniuAdmin from './MeniuAdmin';

const mapStateToProps = (state) => {
  return {
    userProfile: state.session.profile,
    uaID: getUaID(state),
    uaTip: getUaTip(state),
    clasaCurenta: getClasaCurenta(state),
    schoolConfig: state.session.schoolConfig,
    permisiuni: state.session.profile ? state.session.profile.permisiuni : null,
    isDirector: isDirector(state),
    isSecretar: isSecretar(state),
    isCadruDidactic: isCadruDidactic(state),
    switchIsDirector: state.session.switchIsDirector,
  };
};

export default connect(mapStateToProps)(MeniuAdmin);
