/*
|--------------------------------------------------------------------------
| Interconnect URL's
|--------------------------------------------------------------------------
|
*/
export const API_URL =
  window.location.port === '9000' ? `${window.location.protocol}//${window.location.hostname}/api/v2` : '/api/v2';
export const WS_URL =
  window.location.port === '9000' ? `${window.location.protocol}//${window.location.hostname}/` : '/';

/*
|--------------------------------------------------------------------------
| Grade systems
|--------------------------------------------------------------------------
|
*/
export const SN_NOTE = '0';
export const SN_CALIFICATIVE = '1';
export const SN_INSIGNE = '2';
export const SN_CAMBRIDGE = '3';

/*
|--------------------------------------------------------------------------
| Tipuri user
|--------------------------------------------------------------------------
|
*/

export const TYPE_ELEV = '1';
export const TYPE_PARINTE = '2';
export const TYPE_CADRU = '3';
export const TYPE_ADMIN = '4';
export const TYPE_PERS_INST = '5';

/*
|--------------------------------------------------------------------------
| Permisiuni/functii cadre
|--------------------------------------------------------------------------
|
*/

export const PERM_CAD_EDUCATOR = '26';
export const PERM_CAD_INVATATOR = '13';
export const PERM_CAD_PROFESOR = '14';
export const PERM_CAD_SECRETAR = '15';
export const PERM_CAD_DIRECTOR = '16';
export const PERM_CAD_REP_ADSERVIO = '17';
export const PERM_CAD_AUX = '18';

export const PERM_CAD_SYNC = '19';

/*
|--------------------------------------------------------------------------
| Abonamente
|--------------------------------------------------------------------------
|
*/

export const ABO_STANDARD = '1';
export const ABO_PREMIUM = '2';

/*
|--------------------------------------------------------------------------
| Tipuri de nota
|--------------------------------------------------------------------------
|
*/

export const NT_ORAL = '1';
export const NT_TEZA = '2';
export const NT_CORIGENTA = '3';
export const NT_TI = '4';
export const NT_SPECIALITATE = ' 5';
export const NT_TF = '6';
export const NT_TEST = '7';
export const NT_SIMULARE = '8';
export const NT_PROIECT = '9'; // nota normala ca oral/test

/*
|--------------------------------------------------------------------------
| Tipuri fisiere
|--------------------------------------------------------------------------
|
*/

export const FSTIP_DIR = '1';
export const FSTIP_FILE = '2';
export const FSTIP_ATASAMENT = '3';
export const FSTIP_PROIECT = '4';
export const FSTIP_GROUP = '5';

/*
|--------------------------------------------------------------------------
| CONDUITA
|--------------------------------------------------------------------------
*/

export const CONDUITA_POZITIV = '1'; // recompensa
export const CONDUITA_NEGATIV = '2'; // abatere
export const CONDUITA_NEUTRU = '3'; // neutru

// ////////////////////////////////////
