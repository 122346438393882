import {
  PERM_CAD_SECRETAR,
  PERM_CAD_DIRECTOR,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_AUX,
  PERM_CAD_REP_ADSERVIO,
} from '../constants';

export function getClasaCurenta(state) {
  let currentCl = {};

  if (state.session.clase) {
    state.session.clase.forEach((cl) => {
      if (cl.asc.ascIsActiv === '1') {
        currentCl = cl;
      }
    });
    if (Object.keys(currentCl).length === 0) {
      // eslint-disable-next-line prefer-destructuring
      currentCl = state.session.clase[0];
    }
  }

  return currentCl;
}

export function getCurrentElevID(state) {
  let currentElevID;

  if (state.session.elev) {
    currentElevID = state.session.elev.elevID;
  } else {
    currentElevID = state.session.profile && state.session.profile.uaID;
  }

  return currentElevID;
}

export function isDirector(state) {
  let isFound = false;
  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_DIRECTOR) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isSecretar(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_SECRETAR) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isEducator(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_EDUCATOR) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isInvatator(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_INVATATOR) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isProfesor(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_PROFESOR) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isPersAux(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_AUX) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isPersAds(state) {
  let isFound = false;

  if (state.session.profile) {
    // eslint-disable-next-line array-callback-return
    state.session.profile.permisiuni.map((perm) => {
      if (!isFound) {
        if (perm.permID === PERM_CAD_REP_ADSERVIO) {
          isFound = true;
        }
      }
    });
  }
  return isFound;
}

export function isCadruDidactic(state) {
  let isFound = false;

  if (isProfesor(state) || isInvatator(state) || isEducator(state)) {
    isFound = true;
  }
  return isFound;
}

export function getUaID(state) {
  return state.session.profile ? state.session.profile.uaID : null;
}

export function getAscId(state) {
  return state.session.anScolar ? state.session.anScolar.ascID : null;
}

export function getUaTip(state) {
  return state.session.profile && state.session.profile.uaTip;
}

export function getUaLiceuID(state) {
  return state.session.profile && state.session.profile.uaLiceuID;
}

export function getProfile(state) {
  return state.session;
}

export function getClase(state) {
  return state.session.clase;
}
