import { connect } from 'react-redux';
import MeniuPersAux from './MeniuPersAux';

const mapStateToProps = (state) => {
  return {
    schoolConfig: state.session.schoolConfig,
  };
};

export default connect(mapStateToProps)(MeniuPersAux);
