import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import LibraryReducer from '../Modules/Library/Redux/libraryReducer';
import GroupsReducer from '../Modules/Groups/Redux/GroupsReducer';

import SessionReducer from './SessionReducer';

const rootReducer = combineReducers({
  library: LibraryReducer,
  session: SessionReducer,
  groups: GroupsReducer,
});

const store = createStore(
  rootReducer,

  compose(
    applyMiddleware(thunk),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

export default store;
