export default () => {
  let smallScreen = false;
  let mediumScreen = false;
  const minWidthHeight = Math.min(window.screen.width, window.screen.height);
  const navigatorMobi = navigator.userAgent.indexOf('Mobi') > -1;

  if ((minWidthHeight <= 768 && navigatorMobi) || window.innerWidth <= 768) {
    smallScreen = true;
    mediumScreen = false;
  } else if ((minWidthHeight <= 1280 && navigatorMobi) || window.innerWidth <= 1280) {
    mediumScreen = true;
    smallScreen = false;
  } else {
    mediumScreen = false;
    smallScreen = false;
  }

  return {
    mediumScreen,
    smallScreen,
  };
};
