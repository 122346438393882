import Cookie from 'js-cookie';

import Api2 from '../Libs/api';

import { setTokenCookie, removeTokenCookie } from '../Modules/Auth/helpers';
import i18n from '../i18n';
import { TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN, TYPE_PERS_INST } from '../constants';
import { getDefaultUrl } from '../Modules/Site/components/redirect-home/getDefaultUrl';

export const SESSION_SET = 'SESSION_SET';
export const SESSION_UNSET = 'SESSION_UNSET';
export const SESSION_SWITCH_ACCOUNT = 'SESSION_SWITCH_ACCOUNT';
export const MOBILE_VIEW = 'MOBILE_VIEW';
export const UNSET_MOBILE_VIEW = 'UNSET_MOBILE_VIEW';
export const ADS_NATIVE = 'ADS_NATIVE';
export const UNSET_ADS_NATIVE = 'UNSET_ADS_NATIVE';

/** BEGINING OF SESSION FUNCTIONS */

const loadAnScolar = () => {
  return new Promise((res, rej) => {
    Api2.get('/ani/scolari', {
      ascIsActiv: 1,
    })
      .then((response) => {
        if (response.data.length === 1) {
          res(response.data[0]);
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          rej('Invalid response.');
        }
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadSchool = (liceuID) => {
  return new Promise((res, rej) => {
    Api2.get('/scoli/detalii', {
      liceuID,
      cuReprezentantUaID: 1,
    })
      .then((response) => {
        if (response) res(response);
        // eslint-disable-next-line prefer-promise-reject-errors
        else rej('Invalid response.');
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadSchoolConfig = (liceuID) => {
  return new Promise((res, rej) => {
    Api2.get('/scoli/config', {
      liceuID,
    })
      .then((response) => {
        if (response) res(response);
        // eslint-disable-next-line prefer-promise-reject-errors
        else rej('Invalid response.');
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadProfile = (uaID) => {
  return new Promise((res, rej) => {
    Api2.get('/utilizatori/profile', {
      uaID,
      cuPermisiuni: 1,
      cuAbonament: 1,
      withRoles: 1,
    })
      .then(res)
      .catch(rej);
  });
};

const loadClaseElev = (elevID) => {
  return new Promise((res, rej) => {
    Api2.get('/elevi/clase', {
      elevID,
      cuCiclu: 1,
    })
      .then((response) => {
        res(response.data);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadParinteElev = (epID) => {
  return new Promise((res, rej) => {
    Api2.get('/parinti/elev', {
      epID,
    })
      .then((response) => {
        res(response);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadDetaliiCadru = (cadID) => {
  return new Promise((res, rej) => {
    Api2.get('/cadre/detalii', {
      cadID,
    })
      .then((response) => {
        res(response);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadFromStorage = () => {
  return new Promise((res, rej) => {
    if (!window.localStorage) return rej();

    let session = localStorage.getItem('session');
    const tokenData = Api2.getTokenData();

    if (!tokenData) return rej();
    if (!session) return rej();

    session = JSON.parse(session);
    if (!session) return rej();

    // verificam ca tokenul sa nu fie expirat
    if (tokenData.validUntil < Math.round(new Date().getTime() / 1000)) return rej();

    // verificam daca avem acelasi token in cookie si in session
    if (session.tokensig !== Api2.getToken().split('.')[2]) return rej();

    // totul e ok dar facem un refresh la sesiune macar la o ora
    if (Math.round(new Date().getTime() / 1000) - session.lastUpdate > 3600) return rej();

    return res(session);
  });
};

const loadLanguages = () => {
  return new Promise((res, rej) => {
    Api2.get('/enumerations/languages')
      .then((response) => {
        res(response);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const init = () => {
  return new Promise((resolve, reject) => {
    loadFromStorage()
      .then((data) => {
        // caz special pentru admini si pers inst
        if ([TYPE_ADMIN, TYPE_PERS_INST].indexOf(data.profile.uaTip) >= 0 && data.profile.uaLiceuID) {
          Api2.setLiceuID(data.profile.uaLiceuID);
        }
        resolve(data);
      })
      // eslint-disable-next-line consistent-return
      .catch(() => {
        // stergem ce e in localstorage, in caz ca este ceva
        localStorage.removeItem('session');

        const tokenData = Api2.getTokenData();

        if (!tokenData) return reject(); // nu avem token valid so no go

        const newSession = {};

        newSession.lastUpdate = Math.round(new Date().getTime() / 1000);
        // eslint-disable-next-line prefer-destructuring
        newSession.tokensig = Api2.getToken().split('.')[2];
        newSession.validUntil = tokenData.validUntil;

        loadLanguages().then((lang) => {
          newSession.languages = lang.data;
        });

        loadProfile(tokenData.uaID)
          .then((profile) => {
            newSession.profile = profile;

            if (profile.abonament) {
              newSession.abonament = profile.abonament;
              delete newSession.profile.abonament;
            }

            // daca avem cookie de admin incarcam si scoala pentru a o afisa in header
            if (Cookie.get('ADST_ADM') && [TYPE_ADMIN, TYPE_PERS_INST].indexOf(profile.uaTip) === -1) {
              loadSchool(profile.uaLiceuID).then((data) => {
                newSession.school = data;
              });
            }

            if (profile.uaTip === TYPE_ELEV) {
              Promise.all([loadClaseElev(profile.uaID), loadAnScolar(), loadSchoolConfig(profile.uaLiceuID)]).then(
                (responses) => {
                  // eslint-disable-next-line prefer-destructuring
                  newSession.clase = responses[0];
                  // eslint-disable-next-line prefer-destructuring
                  newSession.anScolar = responses[1];
                  // eslint-disable-next-line prefer-destructuring
                  newSession.schoolConfig = responses[2];

                  i18n.changeLanguage(newSession.profile.prefLangID);
                  resolve(newSession);
                }
              );
            } else if (profile.uaTip === TYPE_PARINTE) {
              Promise.all([loadParinteElev(profile.uaID), loadAnScolar(), loadSchoolConfig(profile.uaLiceuID)]).then(
                (responses) => {
                  // eslint-disable-next-line prefer-destructuring
                  newSession.elev = responses[0];
                  // eslint-disable-next-line prefer-destructuring
                  newSession.anScolar = responses[1];
                  // eslint-disable-next-line prefer-destructuring
                  newSession.schoolConfig = responses[2];

                  loadClaseElev(newSession.elev.elevID).then((response) => {
                    newSession.clase = response;

                    i18n.changeLanguage(newSession.profile.prefLangID);
                    resolve(newSession);
                  });
                }
              );
            } else if (profile.uaTip === TYPE_CADRU) {
              Promise.all([loadAnScolar(), loadSchoolConfig(profile.uaLiceuID)]).then((responses) => {
                // eslint-disable-next-line prefer-destructuring
                newSession.anScolar = responses[0];
                // eslint-disable-next-line prefer-destructuring
                newSession.schoolConfig = responses[1];

                loadDetaliiCadru(profile.uaID).then((response) => {
                  newSession.cadru = response;

                  i18n.changeLanguage(newSession.profile.prefLangID);
                  resolve(newSession);
                });
              });
            } else if (profile.uaTip === TYPE_ADMIN || profile.uaTip === TYPE_PERS_INST) {
              if (profile.uaLiceuID) {
                Api2.setLiceuID(profile.uaLiceuID);

                Promise.all([loadAnScolar(), loadSchool(profile.uaLiceuID), loadSchoolConfig(profile.uaLiceuID)])
                  .then((responses) => {
                    // eslint-disable-next-line prefer-destructuring
                    newSession.anScolar = responses[0];
                    // eslint-disable-next-line prefer-destructuring
                    newSession.school = responses[1];
                    // eslint-disable-next-line prefer-destructuring
                    newSession.schoolConfig = responses[2];

                    i18n.changeLanguage(newSession.profile.prefLangID);
                    resolve(newSession);
                  })
                  .catch(reject);
              } else {
                i18n.changeLanguage(newSession.profile.prefLangID);
                resolve(newSession);
              }
            }
          })
          .catch(() => {
            // some error occured, clear cookie
            removeTokenCookie();
            reject();
          });
      });
  });
};

export const sessionInit = () => {
  return (dispatch) => {
    init()
      .then((data) => {
        // eslint-disable-next-line no-param-reassign
        data.ready = true;
        dispatch({
          type: SESSION_SET,
          data,
        });

        // tell native application that session could not be initialized
        if (window.NATIVE) {
          window.NATIVE.postMessage(
            JSON.stringify({
              type: 'session_init',
              status: true,
            })
          );
        }
      })
      .catch(() => {
        // tell native application that session could not be initialized
        if (window.NATIVE) {
          window.NATIVE.postMessage(
            JSON.stringify({
              type: 'session_init',
              status: false,
            })
          );
        }

        dispatch({
          type: SESSION_SET,
          data: { ready: true },
        });
      });
  };
};

export const reloadeProfile = () => {
  return (dispatch) => {
    const session = localStorage.getItem('session');
    const parsedSesion = JSON.parse(session);
    loadProfile().then((profile) => {
      const newSession = {
        ...parsedSesion,
        profile,
      };

      if (profile.abonament) {
        newSession.abonament = profile.abonament;
        delete newSession.profile.abonament;
      }

      if (window.localStorage) {
        localStorage.setItem('session', JSON.stringify(newSession));
      }
      dispatch({
        type: SESSION_SET,
        data: { profile },
      });
    });
  };
};

export const sessionUnset = () => {
  removeTokenCookie();
  return {
    type: SESSION_UNSET,
  };
};

export const sessionSet = (data) => {
  return {
    type: SESSION_SET,
    data,
  };
};

export const sessionSwitchAccount = (account, lang) => {
  return (dispatch) => {
    dispatch({
      type: SESSION_UNSET,
    });

    Api2.post('/auth/switch', {
      uaID: account.uaID,
    }).then((response) => {
      setTokenCookie(response);
      loadProfile().then((profile) => {
        getDefaultUrl(account.uaTip, profile, lang);
      });
    });
  };
};

export const sessionSwitchLiceu = (liceuID) => {
  return (dispatch) => {
    dispatch({
      type: SESSION_SET,
      data: { ready: false },
    });

    Api2.setLiceuID(liceuID);
    Promise.all([loadAnScolar(), loadSchool(liceuID), loadSchoolConfig(liceuID)]).then((responses) => {
      const data = {};

      // eslint-disable-next-line prefer-destructuring
      data.anScolar = responses[0];
      // eslint-disable-next-line prefer-destructuring
      data.school = responses[1];
      // eslint-disable-next-line prefer-destructuring
      data.schoolConfig = responses[2];

      data.ready = true;

      dispatch({
        type: SESSION_SET,
        data,
      });
    });
  };
};

export function mobileView() {
  return {
    type: MOBILE_VIEW,
    mobileView: true,
  };
}

export function unsetMobileView() {
  return {
    type: UNSET_MOBILE_VIEW,
    mobileView: false,
  };
}

export function cookiesAdsNative() {
  return {
    type: ADS_NATIVE,
    hasCookiesAdsNative: !!Cookie.get('ads_native'),
  };
}

export function unsetCookiesAdsNative() {
  return {
    type: UNSET_ADS_NATIVE,
    hasCookiesAdsNative: false,
  };
}
