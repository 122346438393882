import { connect } from 'react-redux';
import { getUaID } from 'Redux/SessionHelper';
import Account from './Account';

const mapStateToProps = (state) => {
  return {
    uaID: getUaID(state),
  };
};

export default connect(mapStateToProps)(Account);
