import Cookies from 'js-cookie';

export const haveError = (formErrors, fieldName, errorCode) => {
  if (!formErrors.length) {
    return false;
  }

  const error = formErrors.filter((fieldErr) => fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode);

  return error.length ? error[0] : false;
};

export const removeTokenCookie = () => {
  Cookies.remove('ADST');
};

export const setTokenCookie = (tokenData) => {
  const dateArr = tokenData.atValidUntil.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);

  Cookies.set('ADST', tokenData.atToken, {
    sameSite: 'strict',
    // domain: window.location.hostname,
    secure: !window.location.hostname.match(/^localhost|(\w+)\.adservio\.net$/),
    expires: new Date(dateArr[1], dateArr[2] - 1, dateArr[3], dateArr[4], dateArr[5], dateArr[6]),
  });
};

export const updateWindowLocation = (url) => {
  window.location = url;
};
