import { connect } from 'react-redux';
import { isCadruDidactic } from 'Redux/SessionHelper';
import MeniuProfesor from './MeniuProfesor';

const mapStateToProps = (state) => {
  return {
    schoolConfig: state.session.schoolConfig,
    isCadruDidactic: isCadruDidactic(state),
  };
};

export default connect(mapStateToProps)(MeniuProfesor);
