import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import Icon from 'Components/Content/Icon/Icon';
import { TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_PERS_INST } from '../../../../../../constants';

import buildLegacyUrl, { TYPE_SECRETARIAT } from '../../../../helpers/build-legacy-url';
import addClassActive from '../../../../helpers/add-class-active';

class MeniuDirectorSecretar extends Component {
  constructor(props) {
    super(props);
    const {
      t,
      history,
      i18n: { language },
    } = this.props;

    const translations = {
      secretariatUrl: t('common:text_secretariat_url'), // See helpers/constants.js
    };

    const verifyPathname = (paths) => {
      const urls = paths.map((path) => {
        return buildLegacyUrl(language, TYPE_SECRETARIAT, path, translations);
      });
      return urls.includes(`${history?.location?.pathname}`);
    };

    this.state = {
      statistici: verifyPathname([
        t('legacy-url.path.statistici_elevi'),
        t('legacy-url.path.statistici_note'),
        t('legacy-url.path.statistici_absente'),
        t('legacy-url.path.statistici_clase'),
      ]),
      nomenclatoare: verifyPathname([t('legacy-url.path.specializari'), t('legacy-url.path.materii')]),
    };
  }

  expandMenu = (type) => {
    const { [type]: value } = this.state;
    this.setState({ [type]: !value });
  };

  render() {
    const {
      schoolConfig,
      uaTip,
      isSecretar,
      isDirector,
      isPersAds,
      t,
      i18n,
      history,
      i18n: { language },
    } = this.props;
    const { statistici, nomenclatoare } = this.state;

    const pathname = history?.location?.pathname;
    const translations = {
      secretariatUrl: t('common:text_secretariat_url'),
    };

    return (
      <>
        {isDirector && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, '', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, '', translations))}
          >
            <span>{t('buttons_mm.director')}</span>
            <Icon icon="icon-agent-01" />
          </a>
        )}

        {(isSecretar || isPersAds) && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, '', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, '', translations))}
          >
            <span>{t('buttons_mm.secretariat')}</span>
            <Icon icon="icon-secretariat" />
          </a>
        )}

        <a
          href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'students', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'students', translations))}
        >
          <span>{t('buttons_mm.elevi')}</span>
          <Icon icon="icon-elev" />
        </a>

        <a
          href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'students_transfer/schools', translations)}
          className={addClassActive(
            pathname,
            buildLegacyUrl(language, TYPE_SECRETARIAT, 'students_transfer/schools', translations)
          )}
        >
          <span>{t('buttons_mm.transferuri')}</span>
          <Icon icon="icon-transferuri" />
        </a>

        <a
          href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'teachers', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'teachers', translations))}
        >
          <span>{t('buttons_mm.cadre_didactice')}</span>
          <Icon icon="icon-cadre" />
        </a>

        <button
          type="button"
          onClick={() => this.expandMenu('nomenclatoare')}
          className={`expand-menu ${nomenclatoare ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.nomenclatoare')}</span>
            {nomenclatoare ? (
              <Icon icon="icon-down-arrow" />
            ) : (
              <>
                <Icon icon="icon-down-arrow show-on-hover" />
                <Icon icon="icon-answer-short hide-on-hover" />
              </>
            )}
          </div>

          {nomenclatoare && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'subjects', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'subjects', translations)
                )}
              >
                <span>{t('buttons_mm.materii')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'specializations', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'specializations', translations)
                )}
              >
                <span>{t('buttons_mm.specializari')}</span>
              </a>
            </span>
          )}
        </button>

        <a
          href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'classes', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'classes', translations))}
        >
          <span>{t('buttons_mm.clase')}</span>
          <Icon icon="icon-clasa" />
        </a>

        {(isDirector || isSecretar) && (
          <NavLink
            to={`/${i18n.language}/main-documents`}
            isActive={(match, location) => location.pathname.match(/main-documents(.*)$/)}
          >
            <span>{t('buttons_mm.documents')}</span>
            <Icon icon="icon-fisascolara" />
          </NavLink>
        )}

        <button
          type="button"
          onClick={() => this.expandMenu('statistici')}
          className={`expand-menu ${statistici ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.statistici')}</span>
            {statistici ? (
              <Icon icon="icon-down-arrow" />
            ) : (
              <>
                <Icon icon="icon-down-arrow show-on-hover" />
                <Icon icon="icon-clasamente2 hide-on-hover" />
              </>
            )}
          </div>

          {statistici && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_students', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_students', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_elevi')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_grades', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_grades', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_note')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_attendance', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_attendance', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_absente')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_classes/final_average', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_SECRETARIAT, 'rankings_classes/final_average', translations)
                )}
              >
                <span>{t('buttons_mm.statistici_clase')}</span>
              </a>
            </span>
          )}
        </button>
        {`${schoolConfig?.taxe?.isActive}` === '1' && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'taxes', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'taxes', translations))}
          >
            <span>{t('buttons_mm.taxe')}</span>
            <Icon icon="icon-plati" />
          </a>
        )}

        {`${schoolConfig?.chestionare?.isActive}` === '1' && uaTip !== TYPE_PERS_INST && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'forms', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'forms', translations))}
          >
            <span>{t('buttons_mm.forms')}</span>
            <Icon icon="icon-rapoarte" />
          </a>
        )}

        {`${schoolConfig?.calendar?.isActive}` === '1' && (
          <NavLink
            to={`/${i18n.language}/calendar`}
            isActive={(match, location) => location.pathname.match(/calendar(.*)$/)}
          >
            <span>{t('buttons_mm.calendar')}</span>
            <Icon icon="icon-calendar-round" />
          </NavLink>
        )}

        {`${schoolConfig?.conduita?.isActive}` === '1' && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'behavior', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'behavior', translations))}
          >
            <span>{t('buttons_mm.conduita')}</span>
            <Icon icon="icon-rewards" />
          </a>
        )}

        {(isDirector || isPersAds) && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'cancel_grades', translations)}
            className={addClassActive(
              pathname,
              buildLegacyUrl(language, TYPE_SECRETARIAT, 'cancel_grades', translations)
            )}
          >
            <span>{t('buttons_mm.anulare_note')}</span>
            <Icon icon="icon-aprobare-note" />
          </a>
        )}

        <span className="separator" />
        <NavLink
          to={`/${i18n?.language}/messages`}
          isActive={(match, location) => {
            return location?.pathname.match(/messages(.*)$/);
          }}
        >
          <span>{t('buttons_mm.mesaje')}</span>
          <Icon icon="icon-mesagerie" />
        </NavLink>

        <NavLink
          to={`/${i18n?.language}/files`}
          isActive={(match, location) => {
            return location?.pathname.match(/files(.*)$/);
          }}
        >
          <span>{t('buttons_mm.fisiere')}</span>
          <Icon icon="icon-fisiere" />
        </NavLink>

        {`${schoolConfig?.foto?.isActive}` === '1' &&
          (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE || uaTip === TYPE_CADRU) && (
            <NavLink
              to={`/${i18n?.language}/foto`}
              isActive={(match, location) => {
                return location?.pathname.match(/foto(.*)$/) || location?.pathname.match(/anuar(.*)$/);
              }}
            >
              <span>{t('buttons_mm.foto')}</span>
              <Icon icon="icon-foto22" />
            </NavLink>
          )}

        {`${schoolConfig?.biblioteca?.isActive}` === '1' && (
          <NavLink
            to={`/${i18n.language}/library`}
            isActive={(match, location) => {
              return location?.pathname.match(/library(.*)$/);
            }}
          >
            <span>{t('buttons_mm.biblioteca')}</span>
            <Icon icon="icon-librarie" />
          </NavLink>
        )}

        {schoolConfig && schoolConfig.groups.isActive === '1' && (
          <NavLink
            to={`/${i18n.language}/groups`}
            isActive={(match, location) => location.pathname.match(/groups(.*)$/)}
          >
            <span>{t('buttons_mm.groups')}</span>
            <Icon icon="icon-groups " />
          </NavLink>
        )}

        {`${schoolConfig?.stiri?.isActive}` === '1' && (
          <a
            href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'news', translations)}
            className={addClassActive(pathname, buildLegacyUrl(language, TYPE_SECRETARIAT, 'news', translations))}
          >
            <span>{t('buttons_mm.noutati')}</span>
            <Icon icon="icon-news" />
          </a>
        )}

        <a
          href={buildLegacyUrl(language, TYPE_SECRETARIAT, 'students_reports', translations)}
          className={addClassActive(
            pathname,
            buildLegacyUrl(language, TYPE_SECRETARIAT, 'students_reports', translations)
          )}
        >
          <span>{t('buttons_mm.rapoarte_elevi')}</span>
          <Icon icon="icon-elevi" />
        </a>
        {`${schoolConfig?.edumarket?.isActive}` === '1' && (
          <NavLink
            to={`/${i18n?.language}/edumarket`}
            isActive={(match, location) => {
              return location?.pathname.match(/edumarket(.*)$/);
            }}
          >
            <span>{t('buttons_mm.edumarket')}</span>
            <Icon icon="icon-shopping-cart" />
          </NavLink>
        )}
      </>
    );
  }
}

MeniuDirectorSecretar.propTypes = {
  uaTip: PropTypes.string,
  isDirector: PropTypes.bool,
  isSecretar: PropTypes.bool,
  isPersAds: PropTypes.bool,
  schoolConfig: PropTypes.shape({
    taxe: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    chestionare: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    conduita: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    foto: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    biblioteca: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    groups: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    stiri: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    edumarket: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    calendar: PropTypes.shape({
      isActive: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

MeniuDirectorSecretar.defaultProps = {
  uaTip: '',
  isDirector: false,
  isSecretar: false,
  isPersAds: false,

  schoolConfig: {
    taxe: {
      isActive: '',
    },
    chestionare: {
      isActive: '',
    },
    conduita: {
      isActive: '',
    },
    foto: {
      isActive: '',
    },
    biblioteca: {
      isActive: '',
    },
    groups: {
      isActive: '',
    },
    stiri: {
      isActive: '',
    },
    edumarket: {
      isActive: '',
    },
    calendar: {
      isActive: '',
    },
  },
  history: {
    location: {
      pathname: '',
    },
  },
};

export default withRouter(withTranslation(['Site', 'common'])(MeniuDirectorSecretar));
