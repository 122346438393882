import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Content/Icon/Icon';
import addClassActive from '../../../../helpers/add-class-active';
import buildLegacyUrl from '../../../../helpers/build-legacy-url';
import { TYPE_CADRU } from '../../../../../../constants';
import { useHistory } from '../../../../../../Helpers';

const MeniuProfesor = (props) => {
  const { schoolConfig, isCadruDidactic } = props;

  const { history } = useHistory();
  const { t, i18n } = useTranslation(['Site', 'common']);
  const { language } = i18n;

  const pathname = history?.location?.pathname;
  const translations = {
    teacherUrl: t('common:text_profesor_url'), // See helpers/constants.js
  };

  return (
    <>
      {isCadruDidactic && (
        <a
          href={buildLegacyUrl(language, TYPE_CADRU, '', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_CADRU, '', translations))}
        >
          <span>{t('buttons_mm.clasele_mele')}</span>
          <Icon icon="icon-clasa" />
        </a>
      )}

      {`${schoolConfig?.fisiere?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/files`}
          isActive={(match, location) => {
            return location?.pathname.match(/files(.*)$/);
          }}
        >
          <span>{t('buttons_mm.fisiere')}</span>
          <Icon icon="icon-fisiere" />
        </NavLink>
      )}

      <a
        href={buildLegacyUrl(language, TYPE_CADRU, 'history', translations)}
        className={addClassActive(pathname, buildLegacyUrl(language, TYPE_CADRU, 'history', translations))}
      >
        <span>{t('buttons_mm.istoric')}</span>
        <Icon icon="icon-istoric" />
      </a>

      {`${schoolConfig?.stiri?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/news`}
          isActive={(match, location) => {
            return location?.pathname.match(/news(.*)$/);
          }}
        >
          <span>{t('buttons_mm.noutati')}</span>
          <Icon icon="icon-news" />
        </NavLink>
      )}

      {`${schoolConfig?.activitati_cadre?.isActive}` === '1' && language === 'ro' && (
        <a
          href={buildLegacyUrl(language, TYPE_CADRU, 'other-activities', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_CADRU, 'other-activities', translations))}
        >
          <span>{t('buttons_mm.portofoliu')}</span>
          <Icon icon="icon-activitati-cadre" />
        </a>
      )}

      {`${schoolConfig?.chestionare?.isActive}` === '1' && (
        <a
          href={buildLegacyUrl(language, TYPE_CADRU, 'forms', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_CADRU, 'forms', translations))}
        >
          <span>{t('buttons_mm.forms')}</span>
          <Icon icon="icon-rapoarte" />
        </a>
      )}

      {`${schoolConfig?.calendar?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n.language}/calendar`}
          isActive={(match, location) => location.pathname.match(/calendar(.*)$/)}
        >
          <span>{t('buttons_mm.calendar')}</span>
          <Icon icon="icon-calendar-round" />
        </NavLink>
      )}
      <span className="separator" />

      <NavLink
        to={`/${i18n?.language}/messages`}
        isActive={(match, location) => {
          return location?.pathname.match(/messages(.*)$/);
        }}
      >
        <span>{t('buttons_mm.mesaje')}</span>
        <Icon icon="icon-mesagerie" />
      </NavLink>

      {schoolConfig && schoolConfig.groups.isActive === '1' && (
        <NavLink to={`/${i18n.language}/groups`} isActive={(match, location) => location.pathname.match(/groups(.*)$/)}>
          <span>{t('buttons_mm.groups')}</span>
          <Icon icon="icon-groups " />
        </NavLink>
      )}

      {`${schoolConfig?.rapoarte_elevi?.isActive}` === '1' && (
        <a
          href={buildLegacyUrl(language, TYPE_CADRU, 'students_reports')}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_CADRU, 'students_reports'))}
        >
          <span>{t('buttons_mm.rapoarte_btn')}</span>
          <Icon icon="icon-elevi" />
        </a>
      )}

      {`${schoolConfig?.foto?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/foto`}
          isActive={(match, location) => {
            return location?.pathname.match(/foto(.*)$/) || location?.pathname.match(/anuar(.*)$/);
          }}
        >
          <span>{t('buttons_mm.foto')}</span>
          <Icon icon="icon-foto22" />
        </NavLink>
      )}

      {`${schoolConfig?.biblioteca?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/library`}
          isActive={(match, location) => {
            return location?.pathname.match(/library(.*)$/);
          }}
        >
          <span>{t('buttons_mm.biblioteca')}</span>
          <Icon icon="icon-librarie" />
        </NavLink>
      )}

      {`${schoolConfig?.edumarket?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/edumarket`}
          isActive={(match, location) => {
            return location?.pathname.match(/edumarket(.*)$/);
          }}
        >
          <span>{t('buttons_mm.edumarket')}</span>
          <Icon icon="icon-shopping-cart" />
        </NavLink>
      )}
    </>
  );
};

MeniuProfesor.propTypes = {
  schoolConfig: PropTypes.shape({
    fisiere: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    orar: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    stiri: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    activitati_cadre: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    chestionare: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    groups: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    rapoarte_elevi: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    foto: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    edumarket: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    biblioteca: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    calendar: PropTypes.shape({
      isActive: PropTypes.string,
    }),
  }),
  isCadruDidactic: PropTypes.bool,
};

MeniuProfesor.defaultProps = {
  schoolConfig: {
    fisiere: {
      isActive: '',
    },
    orar: {
      isActive: '',
    },
    stiri: {
      isActive: '',
    },
    activitati_cadre: {
      isActive: '',
    },
    chestionare: {
      isActive: '',
    },
    groups: {
      isActive: '',
    },
    foto: {
      isActive: '',
    },
    biblioteca: {
      isActive: '',
    },
    edumarket: {
      isActive: '',
    },
    calendar: {
      isActive: '',
    },
  },
  isCadruDidactic: false,
};

export default MeniuProfesor;
