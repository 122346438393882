import React from 'react';

import ReactDOM from 'react-dom';

import { I18nextProvider } from 'react-i18next';

import Store from 'Redux/Store';

import { Provider } from 'react-redux';

import i18n from './i18n';

import App from './app';

const appContainer = document.getElementById('react-app');
if (appContainer) {
  ReactDOM.render(
    <Provider store={Store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>,
    appContainer
  );
}

window.resizeModal = function resizeModal(height) {
  const event = new CustomEvent('resizeModal', { detail: { height } });
  document.getElementsByTagName('body')[0].dispatchEvent(event);
};

window.closeModal = function closeModal(update, updateParams) {
  const event = new CustomEvent('closeModal', { detail: { update, updateParams } });
  document.getElementsByTagName('body')[0].dispatchEvent(event);
};
