import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'Components/Content/Icon/Icon';

import addClassActive from '../../../../helpers/add-class-active';
import buildLegacyUrl from '../../../../helpers/build-legacy-url';
import { useHistory } from '../../../../../../Helpers';

const MeniuParinteElev = (props) => {
  const { schoolConfig, clasaCurenta, uaTip } = props;

  const { history } = useHistory();
  const { t, i18n } = useTranslation(['Site', 'common']);
  const { language } = i18n;

  const pathname = history?.location?.pathname;
  const showAdmission = ['11', '12'].includes(`${clasaCurenta?.ast?.astID}`);

  return (
    <>
      {`${schoolConfig?.stiri?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/news`}
          isActive={(match, location) => {
            return location?.pathname.match(/news(.*)$/);
          }}
        >
          <span>{t('buttons_mm.noutati')}</span>
          <Icon icon="icon-news" />
        </NavLink>
      )}

      {/* <NavLink
        to={`/${i18n?.language}/report-card`}
        isActive={(match, location) => {
          return location?.pathname.match(/report-card(.*)$/);
        }}
      >
        <span>{t('buttons_mm.situatie_zi')}</span>
        <Icon icon="icon-note" />
      </NavLink>

      {`${clasaCurenta?.ast?.astClasamentActiv}` === '1' && (
        <NavLink
          to={`/${i18n.language}/progress`}
          isActive={(match, location) => location.pathname.match(/progress(.*)$/)}
        >
          <span>{t('buttons_mm.evolutie')}</span>
          <Icon icon="icon-clasamente2" />
        </NavLink>
      )}
      */}

      {`${schoolConfig?.chestionare?.isActive}` === '1' && (
        <a
          href={buildLegacyUrl(language, uaTip, 'forms')}
          className={addClassActive(pathname, buildLegacyUrl(language, uaTip, 'forms'))}
        >
          <span>{t('buttons_mm.forms')}</span>
          <Icon icon="icon-rapoarte" />
        </a>
      )}

      {`${schoolConfig?.calendar?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n.language}/calendar`}
          isActive={(match, location) => location.pathname.match(/calendar(.*)$/)}
        >
          <span>{t('buttons_mm.calendar')}</span>
          <Icon icon="icon-calendar-round" />
        </NavLink>
      )}
      <span className="separator" />

      <NavLink
        to={`/${i18n?.language}/messages`}
        isActive={(match, location) => {
          return location?.pathname.match(/messages(.*)$/);
        }}
      >
        <span>{t('buttons_mm.mesaje')}</span>
        <Icon icon="icon-mesagerie" />
      </NavLink>

      <NavLink
        to={`/${i18n?.language}/files`}
        isActive={(match, location) => {
          return location?.pathname.match(/files(.*)$/);
        }}
      >
        <span>{t('buttons_mm.fisiere')}</span>
        <Icon icon="icon-fisiere" />
      </NavLink>

      {schoolConfig && schoolConfig.groups && schoolConfig.groups.isActive === '1' && (
        <NavLink to={`/${i18n.language}/groups`} isActive={(match, location) => location.pathname.match(/groups(.*)$/)}>
          <span>{t('buttons_mm.groups')}</span>
          <Icon icon="icon-groups " />
        </NavLink>
      )}

      {`${schoolConfig?.biblioteca?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/library`}
          isActive={(match, location) => {
            return location?.pathname.match(/library(.*)$/);
          }}
        >
          <span>{t('buttons_mm.biblioteca')}</span>
          <Icon icon="icon-librarie" />
        </NavLink>
      )}

      {`${schoolConfig?.rapoarte_elevi?.isActive}` === '1' && (
        <a
          href={buildLegacyUrl(language, uaTip, 'reports_v1')}
          className={addClassActive(pathname, buildLegacyUrl(language, uaTip, 'reports_v1'))}
        >
          <span>{t('buttons_mm.rapoarte_btn')}</span>
          <Icon icon="icon-elevi" />
        </a>
      )}

      {`${schoolConfig?.foto?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/foto`}
          isActive={(match, location) => {
            return location?.pathname.match(/foto(.*)$/) || location?.pathname.match(/anuar(.*)$/);
          }}
        >
          <span>{t('buttons_mm.foto')}</span>
          <Icon icon="icon-foto22" />
        </NavLink>
      )}

      {`${schoolConfig?.admitere?.isActive}` === '1' && showAdmission && (
        <NavLink
          to={`/${i18n?.language}/admission`}
          isActive={(match, location) => {
            return location?.pathname.match(/admission(.*)$/);
          }}
        >
          <span>{t('buttons_mm.admission')}</span>
          <Icon icon="icon-admitere" />
        </NavLink>
      )}

      {`${schoolConfig?.edumarket?.isActive}` === '1' && (
        <NavLink
          to={`/${i18n?.language}/edumarket`}
          isActive={(match, location) => {
            return location?.pathname.match(/edumarket(.*)$/);
          }}
        >
          <span>{t('buttons_mm.edumarket')}</span>
          <Icon icon="icon-shopping-cart" />
        </NavLink>
      )}
    </>
  );
};

MeniuParinteElev.propTypes = {
  uaTip: PropTypes.string,
  clasaCurenta: PropTypes.shape({
    ast: PropTypes.shape({
      astID: PropTypes.string,
      astClasamentActiv: PropTypes.string,
    }),
  }),
  schoolConfig: PropTypes.shape({
    orar: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    stiri: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    chestionare: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    groups: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    foto: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    edumarket: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    biblioteca: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    admitere: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    calendar: PropTypes.shape({
      isActive: PropTypes.string,
    }),
    rapoarte_elevi: PropTypes.shape({
      isActive: PropTypes.string,
    }),
  }),
};

MeniuParinteElev.defaultProps = {
  uaTip: '',
  clasaCurenta: {
    ast: {
      astID: '',
      astClasamentActiv: '',
    },
  },
  schoolConfig: {
    orar: {
      isActive: '',
    },
    stiri: {
      isActive: '',
    },
    chestionare: {
      isActive: '',
    },
    groups: {
      isActive: '',
    },
    foto: {
      isActive: '',
    },
    edumarket: {
      isActive: '',
    },
    biblioteca: {
      isActive: '',
    },
    admitere: {
      isActive: '',
    },
    calendar: {
      isActive: '',
    },
  },
};

export default MeniuParinteElev;
