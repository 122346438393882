import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import PropTypes from 'prop-types';

import { Container, Visible, Hidden, Row, Col } from 'react-grid-system';

import { useTranslation } from 'react-i18next';

import Api2 from 'Libs/api';
import Icon from '../../../../Components/Content/Icon/Icon';
import Link from '../../../../Components/Content/Link';
import PersonTag from '../../../../Components/Content/PersonTag/PersonTag';
import Tooltip from '../../../../Components/Content/Tooltip/Tooltip';
import { getDefaultUrl } from '../redirect-home/getDefaultUrl';
import Account from './components/account';
import Logout from '../../../Auth/LogoutBtn';
import { TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN, TYPE_PERS_INST } from '../../../../constants';

import logoAdservio from '../../images/logo-footer.svg';

import buildLegacyUrl from '../../helpers/build-legacy-url';

import './Header.less';

const Header = (props) => {
  const {
    uaID,
    uaTip,
    userProfile,
    elev,
    isDirector,
    isSecretar,
    clasaCurenta,
    toggleMenu,
    isCadruDidactic,
    school,
    onToggleMenu,
    sessionSwitchAccount,
  } = props;

  const {
    t,
    i18n,
    i18n: { language },
  } = useTranslation(['Site', 'common']);

  const [accounts, setAccounts] = useState([]);
  const [toggleSimpleMenu, setToggleSimpleMenu] = useState(false);
  const [updateProfile, setUpdateProfile] = useState('updateProfile');
  const meniuProf = !!(Cookies.get('MPR') == null || Cookies.get('MPR') === '1');
  const isAdmin = !!Cookies.get('ADST_ADM');

  const getInfoUaTip = () => {
    let response = '';
    switch (userProfile.uaTip) {
      case '1':
        response = t('common:text_student');
        break;
      case '4':
        response = t('common:text_admin');
        break;
      case '5':
        response = t('common:text_pers_inst');
        break;
      default:
        break;
    }
    return response;
  };

  const getInfoPersonFct = () => {
    return userProfile.roles
      .filter((role) => {
        return role.roleVisibleInTitle === '1';
      })
      .map((role) => role.roleName)
      .join(', ');
  };

  const switchMeniuProfesor = (mpr) => {
    if (mpr) {
      Cookies.set('MPR', 1);
      window.location = `/${i18n.language}/${t('common:text_profesor_url')}`;
    } else {
      Cookies.set('MPR', 0);
      window.location = `/${i18n.language}/${t('common:text_secretariat_url')}`;
    }
  };

  const getAccounts = () => {
    if (uaID) {
      Api2.get('/accounts/adservio', {
        uaID,
      }).then((response) => {
        setAccounts(response);
      });
    }
  };

  useEffect(() => {
    return () => {
      setAccounts([]);
      setToggleSimpleMenu(false);
      setUpdateProfile('updateProfile');
    };
  }, []);

  useEffect(() => {
    getAccounts();
  }, [uaID]);

  useEffect(() => {
    setUpdateProfile('updateProfile');
  }, [userProfile]);

  useEffect(() => {
    setUpdateProfile('');
    setUpdateProfile('updateProfile');
  }, [i18n.language]);

  return (
    <div className="comp-header">
      <Container
        fluid
        className={`${uaID ? 'comp-header-content-log' : 'comp-header-content'}`}
        style={{ padding: '0' }}
      >
        <Row nogutter>
          {uaID && (
            <>
              <Col lg={2} md={2} xs={2}>
                <Visible md sm xs>
                  <button
                    type="button"
                    className={`mobile-menu ${toggleMenu ? 'active' : ''}`}
                    onClick={() => onToggleMenu()}
                  >
                    {!toggleMenu && (
                      <>
                        <Icon icon="icon-meniu-site" />
                        Meniu
                      </>
                    )}
                    {toggleMenu && <Icon icon="icon-inchide-meniu" />}
                  </button>
                </Visible>
                <Hidden md sm xs>
                  <p
                    onClick={() => getDefaultUrl(uaTip, userProfile, i18n.language)}
                    className="logo-header"
                    role="presentation"
                  >
                    <img src={`${logoAdservio}`} alt="logoAdservio" />
                  </p>
                </Hidden>
              </Col>
              <Col lg={10} md={10} xs={10}>
                <div className="header-content-right">
                  <div className="wrapper-school-info">
                    {isAdmin && school && (school.liceuNume || school.liceuAdresa) && (
                      <div className="school-info">
                        <span>{school.liceuNume}</span>
                        {school.liceuAdresa && (
                          <p> {`${school.liceuAdresa.adrLocalitate} ${school.liceuAdresa.adrJudet.judNume}`}</p>
                        )}
                      </div>
                    )}
                  </div>

                  <Tooltip
                    WrapperClass="tooltip-preferinte"
                    shouldUpdate={updateProfile ? 'updateProfile' : ''}
                    hideOnClick
                    worksOnClick
                  >
                    <div className="info-profile">
                      <PersonTag styleTag="avatar" tooltip={false} person={userProfile} />

                      <div className="info-profile-right">
                        {(userProfile.uaFirstName || userProfile.uaLastName) && (
                          <p>{`${userProfile.uaFirstName} ${userProfile.uaLastName}`}</p>
                        )}

                        {userProfile.uaTip === TYPE_PARINTE && !userProfile.uaFirstName && !userProfile.uaLastName && (
                          <i>{t('common:text_parent')}</i>
                        )}

                        {userProfile.uaTip === TYPE_PARINTE && (
                          <p>{`${t('common:text_student')}: ${elev.elevPrenume}`}</p>
                        )}

                        {(userProfile.uaTip === TYPE_ELEV ||
                          userProfile.uaTip === TYPE_ADMIN ||
                          userProfile.uaTip === TYPE_PERS_INST) && <p>{getInfoUaTip()}</p>}

                        {userProfile.uaTip === TYPE_CADRU && <p>{getInfoPersonFct()}</p>}
                      </div>

                      <Icon icon="icon-sageata-dreapta" className="arrow-rotate" />
                    </div>

                    <div>
                      {/* administrator */}
                      {isAdmin && (
                        <p className="account-admin">
                          <a href={`/${i18n.language}/home/account/admin`} className="row-submenu-header">
                            <Icon icon="icon-admin" />
                            {t('header.administrator')}
                          </a>
                        </p>
                      )}

                      {/* daca sunt conturi asociate */}
                      {accounts &&
                        accounts.map((account) => {
                          return (
                            `${account.uaID}` !== `${uaID}` && (
                              <Account
                                key={account.uaID}
                                onClick={() => sessionSwitchAccount(account, userProfile?.prefLangID)}
                                accountInfo={account}
                              />
                            )
                          );
                        })}

                      {isCadruDidactic && !meniuProf && (
                        <button type="button" onClick={() => switchMeniuProfesor(true)} className="row-submenu-header">
                          <Icon icon="icon-cadre-stick" />
                          {t('header.clasele_mele')}
                        </button>
                      )}

                      {isDirector && meniuProf && (
                        <button type="button" onClick={() => switchMeniuProfesor(false)} className="row-submenu-header">
                          <Icon icon="icon-agent-01" />
                          {t('header.director')}
                        </button>
                      )}

                      {isSecretar && meniuProf && (
                        <button type="button" onClick={() => switchMeniuProfesor(false)} className="row-submenu-header">
                          <Icon icon="icon-secretariat" />
                          {t('header.secretariat')}
                        </button>
                      )}

                      {(uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) &&
                        `${clasaCurenta.ast.astID}` !== '11' &&
                        `${clasaCurenta.ast.astID}` !== '12' && (
                          <a href={buildLegacyUrl(language, uaTip, 'subscription')}>
                            <Icon icon="icon-abonament" />
                            {t('header.abonament')}
                          </a>
                        )}
                      <Link to={`/${i18n.language}/preferences/security`}>
                        <Icon icon="icon-keys" />
                        {t('header.securitate')}
                      </Link>

                      <Link to={`/${i18n.language}/preferences/general`}>
                        <Icon icon="icon-setari" />
                        {t('header.preferinte')}
                      </Link>

                      <Logout />
                    </div>
                  </Tooltip>
                </div>
              </Col>
            </>
          )}
          {!uaID && (
            <>
              <div className="header-menu">
                <Visible md sm xs>
                  <button
                    type="button"
                    className={`mobile-menu ${toggleSimpleMenu ? 'active' : ''}`}
                    onClick={() => setToggleSimpleMenu(!toggleSimpleMenu)}
                  >
                    {!toggleSimpleMenu && (
                      <>
                        <Icon icon="icon-meniu-site" />
                        Meniu
                      </>
                    )}

                    {toggleSimpleMenu && <Icon icon="icon-inchide-meniu" />}
                  </button>
                </Visible>

                <p
                  onClick={() => getDefaultUrl(uaTip, userProfile, i18n.language)}
                  className="logo-header"
                  role="presentation"
                >
                  <img src={`${logoAdservio}`} alt="logoAdservio" />
                </p>
              </div>
            </>
          )}
        </Row>
      </Container>
    </div>
  );
};

Header.propTypes = {
  userProfile: PropTypes.shape({
    uaFirstName: PropTypes.string,
    uaLastName: PropTypes.string,
    uaTip: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        roleID: PropTypes.string,
        roleVisibleInTitle: PropTypes.string,
      })
    ),
    prefLangID: PropTypes.string,
  }),
  uaID: PropTypes.string,
  uaTip: PropTypes.string,

  elev: PropTypes.shape({
    elevPrenume: PropTypes.string,
  }),
  school: PropTypes.shape({
    liceuNume: PropTypes.string,
    liceuAdresa: PropTypes.shape({
      adrLocalitate: PropTypes.string,
      adrJudet: PropTypes.shape({
        judNume: PropTypes.string,
      }),
    }),
  }),
  clasaCurenta: PropTypes.shape({
    ast: PropTypes.shape({
      astID: PropTypes.string,
    }),
  }),
  isDirector: PropTypes.bool,
  isSecretar: PropTypes.bool,
  isCadruDidactic: PropTypes.bool,
  toggleMenu: PropTypes.bool,

  onToggleMenu: PropTypes.func,
  sessionSwitchAccount: PropTypes.func,
};

Header.defaultProps = {
  userProfile: {
    uaFirstName: '',
    uaLastName: '',
    uaTip: '',
  },
  elev: {
    elevPrenume: '',
  },
  school: {
    liceuNume: '',
    liceuAdresa: {
      adrLocalitate: '',
      adrJudet: {
        judNume: '',
      },
    },
  },
  clasaCurenta: {
    ast: {
      astID: '',
    },
  },
  uaID: '',
  uaTip: '',
  isDirector: false,
  isSecretar: false,
  isCadruDidactic: false,
  toggleMenu: false,
  onToggleMenu: () => {},
  sessionSwitchAccount: () => {},
};

export default Header;
