import Cookies from 'js-cookie';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { API_URL } from '../constants';
import { removeTokenCookie } from '../Modules/Auth/helpers';

const { CancelToken } = axios;
const Api = {
  host: API_URL,
  _liceuID: null,

  getToken() {
    return Cookies.get('ADST');
  },

  getTokenData() {
    let decoded;
    try {
      decoded = jwtDecode(this.getToken());
      if (!decoded) return false;
    } catch {
      return false;
    }

    return {
      atID: decoded[0],
      uaID: decoded[1],
      uaTip: decoded[2],
      validUntil: decoded[3],
    };
  },

  getLiceuID() {
    // eslint-disable-next-line no-underscore-dangle
    return Api._liceuID;
  },

  setLiceuID(liceuID) {
    // eslint-disable-next-line no-underscore-dangle
    Api._liceuID = liceuID;
  },

  getUaID() {
    // eslint-disable-next-line no-console
    console.log('API.getUaID is deprecated. Refactor ASAP');

    const decoded = jwtDecode(Api.getToken());
    return decoded ? decoded[1] : false;
  },
  getUaTip() {
    // eslint-disable-next-line no-console
    console.log('API.getUaTip is deprecated. Refactor ASAP');

    const decoded = jwtDecode(Api.getToken());

    return decoded ? decoded[2] : false;
  },

  get(url, params) {
    const source = CancelToken.source();

    const options = {
      method: 'get',
      baseURL: this.host,
      url,
      params: this.addExtraParams(params),
      cancelToken: source.token,
    };

    return this.call(options, source);
  },

  post(url, data) {
    const options = {
      method: 'post',
      baseURL: this.host,
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: [this.addExtraParams.bind(this), this.getFormData],
      url,
      data,
    };

    return this.call(options);
  },

  put(url, data) {
    const options = {
      method: 'put',
      baseURL: this.host,
      transformRequest: [this.addExtraParams.bind(this), this.getFormData],
      url,
      data,
    };

    return this.call(options);
  },

  del(url, data) {
    const options = {
      method: 'delete',
      baseURL: this.host,
      url,
      params: this.addExtraParams(data),
    };

    return this.call(options);
  },

  call(options, source) {
    const newPromise = new Promise((res, rej) => {
      const enhancedOptions = options;
      if (this.getToken()) {
        enhancedOptions.headers = {
          Authorization: `Bearer ${this.getToken()}`,
          'X-App-Version': 'web 1.0',
        };
      }

      axios(enhancedOptions)
        .then((data) => {
          res(data.data);
        })
        .catch((error) => {
          rej(error);
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            removeTokenCookie();
            window.location.href = '/';
          }
        });
    });

    newPromise.cancelCall = (reason) => {
      let enhancedReason = reason;

      if (!enhancedReason) {
        enhancedReason = 'Operation canceled by the user.';
      }

      return source ? source.cancel(enhancedReason) : false;
    };
    return newPromise;
  },

  getFormData(data) {
    const form = new FormData();

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        if (!data[key].length) {
          form.append(`${key}[]`, '');
        }
        data[key].forEach((value) => {
          form.append(`${key}[]`, value);
        });

        return;
      }

      form.append(key, data[key]);
    });

    return form;
  },

  addExtraParams(data) {
    // if (Api.getToken()) data = Object.assign({ _token: this.getToken() }, data);
    let enhancedData = data;

    if (Api.getLiceuID()) {
      enhancedData = { _liceuID: this.getLiceuID(), ...data };
    }

    return enhancedData;
  },
};

export default Api;
