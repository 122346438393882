import { connect } from 'react-redux';
import {
  getClasaCurenta,
  getUaID,
  getUaTip,
  isCadruDidactic,
  isDirector,
  isPersAds,
  isPersAux,
  isSecretar,
} from 'Redux/SessionHelper';
import MainMenu from './MainMenu';

const mapStateToProps = (state) => {
  return {
    userProfile: state.session.profile,
    uaID: getUaID(state),
    uaTip: getUaTip(state),
    clasaCurenta: getClasaCurenta(state),
    schoolConfig: state.session.schoolConfig,
    permisiuni: state.session.profile ? state.session.profile.permisiuni : null,
    isDirector: isDirector(state),
    isSecretar: isSecretar(state),
    isCadruDidactic: isCadruDidactic(state),
    isPersAux: isPersAux(state),
    isPersAds: isPersAds(state),
    switchIsDirector: state.session.switchIsDirector,
  };
};

export default connect(mapStateToProps)(MainMenu);
