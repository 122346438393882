/* eslint-disable import/prefer-default-export */
import Cookies from 'js-cookie';
import {
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_CADRU,
  TYPE_ADMIN,
  TYPE_PERS_INST,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_SECRETAR,
  PERM_CAD_DIRECTOR,
  PERM_CAD_REP_ADSERVIO,
} from '../../../../constants';

export const getDefaultUrl = (uaTip, profile, lang, link) => {
  let defaultUrl = '';
  let permisiuni = [];

  if (uaTip) {
    switch (uaTip) {
      case TYPE_ELEV:
      case TYPE_PARINTE:
        defaultUrl = `/${lang}/news`;
        break;
      case TYPE_CADRU:
        permisiuni = profile.permisiuni.map((v) => v.permID);
        if ([PERM_CAD_EDUCATOR, PERM_CAD_INVATATOR, PERM_CAD_PROFESOR].filter((x) => permisiuni.includes(x)).length) {
          Cookies.set('MPR', 1);
          defaultUrl = `/${lang}/teacher`;
        } else if (
          [PERM_CAD_SECRETAR, PERM_CAD_DIRECTOR, PERM_CAD_REP_ADSERVIO].filter((x) => permisiuni.includes(x)).length
        ) {
          Cookies.set('MPR', 0);
          defaultUrl = `/${lang}/secretary`;
        } else {
          Cookies.set('MPR', 1);
          defaultUrl = `/${lang}/news`;
        }
        break;

      case TYPE_ADMIN:
        defaultUrl = `/${lang}/admin`;
        break;
      case TYPE_PERS_INST:
        defaultUrl = `/${lang}/institutions`;
        break;
      default:
        defaultUrl = `/${lang}`;
        break;
    }
  } else {
    defaultUrl = `/${lang}${link ? `/${link}` : ''}`;
  }

  window.location = defaultUrl;
};
