import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'Components/Content/Icon/Icon';
import { TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN, TYPE_PERS_INST } from '../../../../../../constants';

import './Account.less';

const Account = (props) => {
  const { accountInfo, onClick } = props;

  return (
    <p className="comp-account" aria-hidden="true" onClick={onClick}>
      {accountInfo?.uaPrefAvatar ? (
        <img src={accountInfo?.uaPrefAvatar} alt="avatar" className="avatar" />
      ) : (
        <Icon icon="icon-profile-neutral-round" className="avatar-icon" />
      )}

      <span className="user-navigation-info">
        <span className="user-info-row user-info-type">
          {accountInfo?.roles
            .filter((el) => el.roleVisibleInTitle === '1')
            .map((el) => el.roleName)
            .join(', ')}
        </span>
        {accountInfo?.uaTip === TYPE_PARINTE && (
          <b>
            {`${accountInfo?.elevNume} ${accountInfo?.elevPrenume}`}
            <br />
          </b>
        )}
        {accountInfo?.uaTip === TYPE_CADRU && (
          <b>
            {`${accountInfo?.uaFirstName} ${accountInfo?.uaLastName}`}
            <br />
          </b>
        )}
        {(accountInfo?.uaTip === TYPE_ELEV ||
          accountInfo?.uaTip === TYPE_PARINTE ||
          accountInfo?.uaTip === TYPE_CADRU) && (
          <span className="user-info-row">{`${accountInfo?.liceu?.liceuClasificare} ${accountInfo?.liceu?.liceuNume}, ${accountInfo?.liceu?.liceuOras}`}</span>
        )}
        {accountInfo?.uaTip === TYPE_PERS_INST && <span className="user-info-row">{accountInfo?.inst?.instNume}</span>}
        {(accountInfo?.uaTip === TYPE_ADMIN || accountInfo?.uaTip === TYPE_PERS_INST) && (
          <span className="user-info-row">{`${accountInfo?.uaFirstName} ${accountInfo?.uaLastName}`}</span>
        )}
      </span>
    </p>
  );
};

Account.propTypes = {
  accountInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        roleName: PropTypes.string,
      })
    ),
    uaPrefAvatar: PropTypes.string,
    uaFirstName: PropTypes.string,
    uaLastName: PropTypes.string,
    uaTip: PropTypes.string,
    elevNume: PropTypes.string,
    elevPrenume: PropTypes.string,
    inst: PropTypes.shape({
      instNume: PropTypes.string,
    }),
    liceu: PropTypes.shape({
      liceuClasificare: PropTypes.string,
      liceuNume: PropTypes.string,
      liceuOras: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
};

Account.defaultProps = {
  accountInfo: {
    roleName: [],
    uaPrefAvatar: '',
    uaFirstName: '',
    uaLastName: '',
    uaTip: '',
    elevNume: '',
    elevPrenume: '',
    inst: {},
    liceu: {
      liceuClasificare: '',
      liceuNume: '',
      liceuOras: '',
    },
  },
  onClick: () => {},
};

export default Account;
