import { createSelector } from 'reselect';

/**
 * selectSession
 *
 * Select session store slice
 *
 * @state {object} Redux store
 *
 * @return Redux store slice
 */
const selectSession = (state) => state.session;

/**
 * get
 *
 * Select a value from the redux session slice
 *
 * @queryParam {string} Value to be returned
 *
 * @return Redux stored value
 */
export const get = (queryParam) => createSelector(selectSession, (state) => state[queryParam]);

/**
 * getCurrentElevID
 *
 * Select a student id from the profile when you're logged in as a student
 * or from student when you're logged in as a parent or other account
 *
 * @return Redux stored value
 */
export const getCurrentElevID = () =>
  createSelector(selectSession, (state) => {
    const { elev, profile } = state;

    if (elev) {
      return elev.elevID;
    }

    return profile.uaID;
  });

/**
 * getProfileID
 *
 * Select the profile id from the redux session slice
 *
 * @return Redux stored value
 */
export const getProfileID = () =>
  createSelector(selectSession, (state) => {
    const { profile } = state;

    return profile.uaID;
  });

/**
 * getCurrentElevName
 *
 * Select the student name from the profile when you're logged in as a student
 * or from student when you're logged in as a parent or other account
 *
 * @return String containing lastname and firsname
 */
export const getCurrentElevName = () =>
  createSelector(selectSession, (state) => {
    const { elev, profile } = state;

    if (elev) {
      return `${elev.elevNume} ${elev.elevPrenume}`;
    }

    return `${profile.uaLastName} ${profile.uaFirstName}`;
  });

/**
 * getClasaCurenta
 *
 * Select the current class from a class collection based on activIndicator "1"
 * or the first collection item if current class was not found by the activIndicator
 *
 * @return Object with class informations
 */
export const getClasaCurenta = () =>
  createSelector(selectSession, (state) => {
    const { clase } = state;
    const [firstClass] = clase;

    let currentClass = {};

    if (clase) {
      clase.forEach((cl) => {
        const {
          asc: { ascIsActiv },
        } = cl;

        if (ascIsActiv === '1') {
          currentClass = cl;
        }
      });

      if (Object.keys(currentClass).length === 0) {
        currentClass = firstClass;
      }
    }

    return currentClass;
  });

/**
 * getClase
 *
 * Select the profile id from the redux session slice
 *
 * @return Redux stored value
 */
export const getClase = () =>
  createSelector(selectSession, (state) => {
    const { clase } = state;

    return clase;
  });

/**
 * getFromProfile
 *
 * Select a value from session profile store slice
 *
 * @queryParam {string} Value to select eg. uaID, uaTip, uaLiceuID, ...
 *
 * @return String with requested value or null if it's not stored
 */
export const getFromProfile = (queryParam) =>
  createSelector(selectSession, (state) => {
    const { profile } = state;

    return profile ? profile[queryParam] : null;
  });

/**
 * getFromAnScolar
 *
 * Select a value from session anScolar store slice
 *
 * @queryParam {string} Value to select eg. ascID, ...
 *
 * @return String with requested value or null if it's not stored
 */
export const getFromAnScolar = (queryParam) =>
  createSelector(selectSession, (state) => {
    const { anScolar } = state;

    return anScolar ? anScolar[queryParam] : null;
  });
