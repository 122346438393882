import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Icon from 'Components/Content/Icon/Icon';
import { withRouter } from 'react-router';
import addClassActive from '../../../../helpers/add-class-active';
import buildLegacyUrl from '../../../../helpers/build-legacy-url';
import { TYPE_ADMIN } from '../../../../../../constants';

class MeniuAdmin extends Component {
  constructor(props) {
    super(props);
    const {
      t,
      history,
      i18n: { language },
    } = this.props;

    const translations = {
      adminUrl: t('common:text_admin_url'), // See helpers/constants.js
    };

    const verifyPathname = (paths) => {
      const urls = paths.map((path) => {
        return buildLegacyUrl(language, TYPE_ADMIN, path, translations);
      });
      return urls.includes(`${history?.location?.pathname}`);
    };

    this.state = {
      utilizatori: verifyPathname([
        t('legacy-url.path.utilizatori_elevi'),
        t('legacy-url.path.utilizatori_cadre'),
        t('legacy-url.path.utilizatori_personal_inst'),
        t('legacy-url.path.utilizatori_admini'),
      ]),

      plati: verifyPathname([
        t('legacy-url.path.plati_abonamente'),
        t('legacy-url.path.plati_chitante'),
        t('legacy-url.path.plati_gratuitati'),
        t('legacy-url.path.plati_tip_gratuitati'),
        t('legacy-url.path.plati_proforme'),
        t('legacy-url.path.plati_fiscale'),
      ]),
      dev: verifyPathname([
        t('legacy-url.path.dev_query_runner'),
        t('legacy-url.path.dev_code_exec'),
        t('legacy-url.path.dev_email_complaints'),
        t('legacy-url.path.dev_config_master'),
      ]),
      admin_statistici: verifyPathname([
        t('legacy-url.path.admin_statistici_incasari'),
        t('legacy-url.path.admin_statistici_auth'),
        t('legacy-url.path.admin_statistici_utilizatori'),
        t('legacy-url.path.admin_statistici_queues'),
      ]),
      neuron: verifyPathname([t('legacy-url.path.admin_neuron_utilizatori'), t('legacy-url.path.admin_neuron_plati')]),
    };
  }

  expandMenu = (type) => {
    const { [type]: value } = this.state;
    this.setState({ [type]: !value });
  };

  render() {
    const {
      userProfile,
      t,
      i18n,
      history,
      i18n: { language },
    } = this.props;

    const { utilizatori, plati, dev, admin_statistici: adminStatistici, neuron } = this.state;

    const pathname = history?.location?.pathname;
    const translations = {
      adminUrl: t('common:text_admin_url'),
    };

    return (
      <>
        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, '', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, '', translations))}
        >
          <span>{t('buttons_mm.admin')}</span>
          {userProfile?.uaSex === '2' ? <Icon icon="icon-adminfemale" /> : <Icon icon="icon-adminmale" />}
        </a>

        <button
          type="button"
          onClick={() => this.expandMenu('utilizatori')}
          className={`expand-menu ${utilizatori ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.utilizatori')}</span>
            {utilizatori ? <Icon icon="icon-down-arrow" /> : <Icon icon="icon-parinti" />}
          </div>
          {utilizatori && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'students/search', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'students/search', translations)
                )}
              >
                <span>{t('buttons_mm.utilizatori_elevi')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'cadre/cauta', translations)}
                className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'cadre/cauta', translations))}
              >
                <span>{t('buttons_mm.utilizatori_cadre')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'personal_inst', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'personal_inst', translations)
                )}
              >
                <span>{t('buttons_mm.utilizatori_personal_inst')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'admini/cauta', translations)}
                className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'admini/cauta', translations))}
              >
                <span>{t('buttons_mm.utilizatori_admini')}</span>
              </a>
            </span>
          )}
        </button>

        <button
          type="button"
          onClick={() => this.expandMenu('plati')}
          className={`expand-menu ${plati ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.plati')}</span>
            {plati ? <Icon icon="icon-down-arrow" /> : <Icon icon="icon-plati" />}
          </div>

          {plati && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/abonamente', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/abonamente', translations)
                )}
              >
                <span>{t('buttons_mm.plati_abonamente')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/chitante', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/chitante', translations)
                )}
              >
                <span>{t('buttons_mm.plati_chitante')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/gratuitati', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/gratuitati', translations)
                )}
              >
                <span>{t('buttons_mm.plati_gratuitati')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/gratuitati', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/gratuitati', translations)
                )}
              >
                <span>{t('buttons_mm.plati_tip_gratuitati')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/proforme', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/proforme', translations)
                )}
              >
                <span>{t('buttons_mm.plati_proforme')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'plati/fiscale', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'plati/fiscale', translations)
                )}
              >
                <span>{t('buttons_mm.plati_fiscale')}</span>
              </a>
            </span>
          )}
        </button>

        <button type="button" onClick={() => this.expandMenu('dev')} className={`expand-menu ${dev ? 'active' : ''}`}>
          <div className="expand-menu-content">
            <span>{t('buttons_mm.dev')}</span>
            {dev ? <Icon icon="icon-down-arrow" /> : <Icon icon="icon-admin" />}
          </div>

          {dev && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'dev/query_runner', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'dev/query_runner', translations)
                )}
              >
                <span>{t('buttons_mm.dev_query_runner')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'dev/code_exec', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'dev/code_exec', translations)
                )}
              >
                <span>{t('buttons_mm.dev_code_exec')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'dev/email_complaints', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'dev/email_complaints', translations)
                )}
              >
                <span>{t('buttons_mm.dev_email_complaints')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'config/master', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'config/master', translations)
                )}
              >
                <span>{t('buttons_mm.dev_config_master')}</span>
              </a>
            </span>
          )}
        </button>

        <button
          type="button"
          onClick={() => this.expandMenu('admin_statistici')}
          className={`expand-menu ${adminStatistici ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.admin_statistici')}</span>
            {adminStatistici ? <Icon icon="icon-down-arrow" /> : <Icon icon="icon-clasamente2" />}
          </div>

          {adminStatistici && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'statistici/incasari', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'statistici/incasari', translations)
                )}
              >
                <span>{t('buttons_mm.admin_statistici_incasari')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'statistici/autentificari', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'statistici/autentificari', translations)
                )}
              >
                <span>{t('buttons_mm.admin_statistici_auth')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'statistici/utilizatori', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'statistici/utilizatori', translations)
                )}
              >
                <span>{t('buttons_mm.admin_statistici_utilizatori')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'statistici/queues', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'statistici/queues', translations)
                )}
              >
                <span>{t('buttons_mm.admin_statistici_queues')}</span>
              </a>
            </span>
          )}
        </button>
        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, 'schools', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'schools', translations))}
        >
          <span>{t('buttons_mm.scoli')}</span>
          <Icon icon="icon-school" />
        </a>

        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, 'tichete', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'tichete', translations))}
        >
          <span>{t('buttons_mm.admin_tichete')}</span>
          <Icon icon="icon-prepay" />
        </a>

        <span className="separator" />

        <NavLink
          to={`/${i18n.language}/messages`}
          isActive={(match, location) => {
            return location.pathname.match(/messages(.*)$/);
          }}
        >
          <span>{t('buttons_mm.mesaje')}</span>
          <Icon icon="icon-mesagerie" />
        </NavLink>

        <NavLink
          to={`/${i18n?.language}/files`}
          isActive={(match, location) => {
            return location?.pathname.match(/files(.*)$/);
          }}
        >
          <span>{t('buttons_mm.fisiere')}</span>
          <Icon icon="icon-fisiere" />
        </NavLink>

        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, 'news')}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'news'))}
        >
          <span>{t('buttons_mm.stiri')}</span>
          <Icon icon="icon-news" />
        </a>

        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, 'newsletter', translations)}
          className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'newsletter', translations))}
        >
          <span>{t('buttons_mm.admin_newsletter')}</span>
          <Icon icon="icon-fisascolara" />
        </a>

        <NavLink
          to={`/${i18n?.language}/library`}
          isActive={(match, location) => {
            return location?.pathname.match(/library(.*)$/);
          }}
        >
          <span>{t('buttons_mm.biblioteca')}</span>
          <Icon icon="icon-librarie" />
        </NavLink>
        <a
          href={buildLegacyUrl(language, TYPE_ADMIN, 'university-admission', translations)}
          className={addClassActive(pathname, '/university-admission')}
        >
          <span>{t('buttons_mm.admitere')}</span>
          <Icon icon="icon-admitere" />
        </a>

        <NavLink
          to={`/${i18n?.language}/edumarket`}
          isActive={(match, location) => {
            return location?.pathname.match(/edumarket(.*)$/);
          }}
        >
          <span>{t('buttons_mm.edumarket')}</span>
          <Icon icon="icon-shopping-cart" />
        </NavLink>

        <a
          href={`https://manual.adservio.ro/web/${i18n?.language}`}
          className="manual-utilizare"
          target="_blank"
          rel="noreferrer"
        >
          <span>{t('buttons_mm.manual')}</span>
          <Icon icon="icon-ajutor2" />
        </a>

        <button
          type="button"
          onClick={() => this.expandMenu('neuron')}
          className={`expand-menu ${neuron ? 'active' : ''}`}
        >
          <div className="expand-menu-content">
            <span>{t('buttons_mm.neuron')}</span>
            {neuron ? <Icon icon="icon-down-arrow" /> : <Icon icon="icon-parinti" />}
          </div>

          {neuron && (
            <span className="expand-menu-submenu">
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'neuron/users', translations)}
                className={addClassActive(pathname, buildLegacyUrl(language, TYPE_ADMIN, 'neuron/users', translations))}
              >
                <span>{t('buttons_mm.admin_neuron_utilizatori')}</span>
              </a>
              <a
                href={buildLegacyUrl(language, TYPE_ADMIN, 'neuron/payment', translations)}
                className={addClassActive(
                  pathname,
                  buildLegacyUrl(language, TYPE_ADMIN, 'neuron/payment', translations)
                )}
              >
                <span>{t('buttons_mm.admin_neuron_plati')}</span>
              </a>
            </span>
          )}
        </button>
      </>
    );
  }
}

MeniuAdmin.propTypes = {
  userProfile: PropTypes.shape({
    uaSex: PropTypes.string,
  }),
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};

MeniuAdmin.defaultProps = {
  userProfile: {
    uaSex: '',
  },
  history: {
    location: {
      pathname: '',
    },
  },
};

export default withRouter(withTranslation(['Site', 'common'])(MeniuAdmin));
