import Cookie from 'js-cookie';

import {
  SESSION_SET,
  SESSION_UNSET,
  MOBILE_VIEW,
  UNSET_MOBILE_VIEW,
  ADS_NATIVE,
  UNSET_ADS_NATIVE,
} from 'Redux/SessionActions';

const initState = {
  profile: null,
  clase: null,
  elev: null,
  cadru: null,
  anScolar: null,
  ready: false,
  lastUpdated: null,
};

const save = (newSession) => {
  // salvam in localstorage
  if (window.localStorage) {
    localStorage.setItem('session', JSON.stringify(newSession));
  }
};

const deleteFn = () => {
  if (window.localStorage) {
    localStorage.removeItem('session');
  }
};

const SessionReducer = (state = initState, action) => {
  switch (action.type) {
    case SESSION_SET:
      // eslint-disable-next-line no-case-declarations
      const newSession = {
        ...state,
        lastUpdated: new Date(),
        ...action.data,
      };

      save(newSession);
      return newSession;

    case SESSION_UNSET:
      deleteFn();

      return {
        ...initState,
      };

    case MOBILE_VIEW:
      return {
        ...state,
        mobileView: true,
      };

    case UNSET_MOBILE_VIEW:
      return {
        ...state,
        mobileView: false,
      };

    case ADS_NATIVE:
      return {
        ...state,
        hasCookiesAdsNative: !!Cookie.get('ads_native'),
      };

    case UNSET_ADS_NATIVE:
      return {
        ...state,
        hasCookiesAdsNative: false,
      };

    default:
      return state;
  }
};

export default SessionReducer;
