import { connect } from 'react-redux';
import { getClasaCurenta, getUaTip } from 'Redux/SessionHelper';
import MeniuParinteElev from './MeniuParinteElev';

const mapStateToProps = (state) => {
  return {
    uaTip: getUaTip(state),
    clasaCurenta: getClasaCurenta(state),
    schoolConfig: state.session.schoolConfig,
  };
};

export default connect(mapStateToProps)(MeniuParinteElev);
