import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Cookies from 'js-cookie';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Api2 from '../../Libs/api';

import { sessionUnset } from '../../Redux/SessionActions';

import Button from '../../Components/Forms/SimpleButton/Button';
import Icon from '../../Components/Content/Icon/Icon';

import './LogoutBtn.less';

class LogoutBtn extends Component {
  logout = () => {
    const { i18n, sessionUnset: propsSessionUnset } = this.props;

    Promise.all([
      Api2.del('/auth'),
      Api2.get(`${window.location.protocol}//${window.location.hostname}/logout`),
    ]).finally(() => {
      Cookies.remove('MPR');
      Cookies.remove('ADST_ADM');

      propsSessionUnset();

      window.location.href = `/${i18n.language}`;
    });
  };

  render() {
    const { t } = this.props;

    return (
      <Button className="comp-logout" color="blue-link" onClick={this.logout}>
        <Icon icon="icon-iesire" />
        <span>{t('header_logout')}</span>
      </Button>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sessionUnset: () => {
      return dispatch(sessionUnset());
    },
  };
};

LogoutBtn.propTypes = {
  i18n: PropTypes.instanceOf(Object).isRequired,
  sessionUnset: PropTypes.func.isRequired,
  t: PropTypes.instanceOf(Object).isRequired,
};

const ConnectedLogoutBtn = connect(null, mapDispatchToProps)(LogoutBtn);

export default withTranslation('Auth')(ConnectedLogoutBtn);
